import React, { useEffect } from "react"
import { navigate } from "gatsby"
import * as PropTypes from "prop-types"
import { connect } from "react-redux"
import { loginOperations, loginSelectors } from "../../../state/auth"
import { createStructuredSelector } from "reselect"
import { Form, Button } from "react-bootstrap"
import PasswordInputForm from "./PasswordInputForm"
import * as styles from "./LoginForm.module.scss"


const LoginForm = ({ isLoggingIn, signInUser, signInUserReset, isError, isLoggedIn }) => {

  useEffect(() => {
    //check if user is already logged in
    if(isLoggedIn){
      navigate("/")
    } else {
      signInUserReset()
    }
  }, [])

  useEffect(()=> {
    //navigate to home page if user has been logged in successfully
    if(isLoggedIn) {
      navigate("/")
    }
  },[isLoggedIn])


  const handleSubmit = (event) => {
    const form = event.currentTarget
    event.preventDefault()

    signInUser(form.elements.email.value, form.elements.password.value)
  }

  return (
    <div className={`d-flex flex-column w-100`}>
      <h2>Sign in</h2>
      <hr className="w-100 mt-0 mb-2" />
      <p className={styles.error}>{isError ? "Invalid username of password" : " "}&nbsp;</p>
      <Form onSubmit={handleSubmit} className="w-100">
        <Form.Group controlId="email" className="mb-3">
          <Form.Label>Email</Form.Label>
          <Form.Control type="email" placeholder="Enter email" required disabled={isLoggingIn}
                        autocomplete="username" />

        </Form.Group>
        <PasswordInputForm
          label="Password"
          disabled={isLoggingIn}
          placeholder="Enter password"
          required={true}
          className="mb-3"
          controlId="password"
          autocomplete="current-password"
        />
        <Button variant="primary" type="submit" disabled={isLoggingIn}>
          Sign in
        </Button>
      </Form>
    </div>
  )
}

LoginForm.propTypes = {
  isLoggingIn: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  signInUser: PropTypes.func.isRequired,
  signInUserReset: PropTypes.func.isRequired,

}

LoginForm.defaultProps = {}

const mapStateToProps = createStructuredSelector({
  isLoggingIn: loginSelectors.isLoggingIn,
  isError: loginSelectors.isError,
  isLoggedIn: loginSelectors.isLoggedIn
})

const mapDispatchToProps = {
  signInUser: loginOperations.signInUser,
  signInUserReset: loginOperations.signInUserReset,
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm)