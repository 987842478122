import React from "react"
import LoginForm from "../components/UI/LoginForm/LoginForm"

import * as style from "./login.module.scss"

import Layout from "../components/Layout"

const PageLogin = () => {

  return (
    <Layout
      staticSeo={{ seoTitle: "Login", seoDescription: "Login page" }}
      location={{ pathname: "login" }}
    >
      <div className={style.container}>
        <LoginForm />
      </div>
    </Layout>
  )
}

export default PageLogin